angular.module('app', [
  // 1st Party Dependencies
  'ngAnimate',
  'ngAria',
  'ngMessages',
  'ngResource',
  'ngSanitize',
  'ngTouch',

  // 3rd Party Dependencies
  'angular-clipboard',
  'angular-ladda',
  'firebase',
  'infinite-scroll',
  'jcs-autoValidate',
  'ngCpfCnpj',
  'ngMap',
  'ngRaven',
  'ngSessionStorage',
  'slugifier',
  'toaster',
  'ui.mask',
  'ui.router',
  'ui.utils.masks'
]);
