angular
  .module('app')
  .component('resetPassword', {
    templateUrl: 'app/components/resetPassword/resetPassword.html',
    controller: ResetPasswordController
  });

/** @ngInject */
function ResetPasswordController($rootScope, $scope, $log, toaster, firebaseAuthFactory, firebaseAuthService) {
  // THIS
  var ctrl = this;

  // SERVICES
  ctrl.firebaseAuthService = firebaseAuthService;

  // OBJECTS
  ctrl.formResetPasswordData = {};

  // STRINGS
  $rootScope.bodyClass = 'reset-password';

  // METHODS
  ctrl.resetPassword = function (email) {
    ctrl.firebaseAuthService
      .resetPassword(email)
      .then(function () {
        ctrl.formResetPasswordData = {};

        $scope.formResetPassword.$setPristine();
        $scope.formResetPassword.$setUntouched();
      });
  };
}
