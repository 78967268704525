angular
  .module('app')
  .filter('isAmex', isAmex)
  .filter('replace', replace);

function isAmex() {
  return function (value) {
    return value === 'Amex' ? 'American Express' : value;
  };
}

function replace() {
  return function (input, from, to) {
    input = input || '';
    from = from || '';
    to = to || '';

    return input.replace(new RegExp(from, 'g'), to);
  };
}
