angular
  .module('app')
  .component('dashboard', {
    templateUrl: 'app/components/dashboard/dashboard.html',
    controller: DashboardController
  });

/** @ngInject */
function DashboardController($rootScope, firebaseAuthService, ticketsService) {
  // THIS
  var ctrl = this;

  // SERVICES
  ctrl.firebaseAuthService = firebaseAuthService;
  ctrl.ticketsService = ticketsService;

  // STRINGS
  $rootScope.bodyClass = 'dashboard';
  ctrl.pageTitle = 'Dashboard';
}
