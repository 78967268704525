angular
  .module('app')
  .run(run);

/** @ngInject */
function run($state, firebaseAuthFactory) {
  $state.defaultErrorHandler(function (reason) {
    if (reason === 'AUTH_REQUIRED') {
      $state.go('signIn');
    }
  });

  firebaseAuthFactory.$onAuthStateChanged(function (firebaseUser) {
    if (!firebaseUser && $state.router.urlRouter.location !== '/assistencia') {
      $state.go('signIn');
    }
  });
}
