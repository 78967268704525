angular
  .module('app')
  .service('retailersService', retailersService);

/** @ngInject */
function retailersService($log, $q, getRetailersFactory) {
  var self = {
    // ARRAYS
    retailersMouraFacil: [],
    retailersRedeMoura: [],

    // OBJECTS
    selectedRetailer: {},

    // FLAGS
    isGettingRetailersMouraFacil: null,
    isGettingRetailersRedeMoura: null,

    // METHODS
    getRetailersMouraFacil: function (latitude, longitude) {
      if (self.isGettingRetailersMouraFacil) {
        return;
      }

      self.isGettingRetailersMouraFacil = true;

      var defer = $q.defer();

      getRetailersFactory
        .get({
          activeOnly: true,
          distance: 0.3,
          latitude: latitude,
          limit: 500,
          longitude: longitude
        })
        .$promise
        .then(function (response) {
          self.retailersMouraFacil = [];

          self.retailersMouraFacil = response.retailers
            .filter(function (retailer) {
              return retailer.location && retailer.location.length > 0;
            })
            .map(function (retailer) {
              retailer.latitude = retailer.location[0];
              retailer.longitude = retailer.location[1];

              delete retailer.location;

              return retailer;
            });

          self.isGettingRetailersMouraFacil = false;

          defer.resolve();
        }, function (reason) {
          $log.error('retailersService.getRetailersMouraFacil', reason);

          self.isGettingRetailersMouraFacil = false;
        });

      return defer.promise;
    },
    getRetailersRedeMoura: function (latitude, longitude) {
      if (self.isGettingRetailersRedeMoura) {
        return;
      }

      self.isGettingRetailersRedeMoura = true;

      var defer = $q.defer();

      getRetailersFactory
        .get({
          distance: 15,
          limit: 15,
          location: latitude + ',' + longitude,
          mf: false
        })
        .$promise
        .then(function (response) {
          self.retailersRedeMoura = response.revendas;

          self.isGettingRetailersRedeMoura = false;

          defer.resolve();
        }, function (reason) {
          $log.error('retailersService.getRetailersRedeMoura', reason);

          self.isGettingRetailersRedeMoura = false;
        });

      return defer.promise;
    }
  };

  return self;
}
