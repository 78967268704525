angular
  .module('app')
  .config(assistenciaRoutes);

/** @ngInject */
function assistenciaRoutes($stateProvider) {
  $stateProvider.state('assistencia', {
    url: '/assistencia',
    component: 'assistencia'
  });
}
