angular
  .module('app')
  .service('vehicleService', vehicleService);

/** @ngInject */
function vehicleService($log, getTopVehiclesFactory, searchVehiclesFactory) {
  var self = {
    // ARRAYS
    topVehicles: [],
    vehicles: [],

    // OBJECTS
    selectedVehicle: {},

    // NUMBERS
    limit: 24,

    // STRING
    vehicleType: 'CARRO,CAMINHAO',

    // FLAGS
    hasMoreTopVehicles: true,
    isGettingMoreTopVehicles: null,
    isGettingTopVehicles: null,
    isSearchingVehicles: null,

    // METHODS
    getMoreTopVehicles: function (cityId) {
      if (self.isGettingMoreTopVehicles) {
        return;
      }

      if (self.hasMoreTopVehicles) {
        self.isGettingMoreTopVehicles = true;

        getTopVehiclesFactory
          .query({
            cidade: cityId,
            limit: self.limit,
            offset: self.topVehicles.length,
            vehicleType: self.vehicleType
          })
          .$promise
          .then(function (response) {
            self.topVehicles = self.topVehicles.concat(response);
            self.vehicles = self.topVehicles;

            if (response.length === 0) {
              self.hasMoreTopVehicles = false;
            }

            self.isGettingMoreTopVehicles = false;
          }, function (reason) {
            $log.error('vehicleService.getMoreTopVehicles', reason);

            self.isGettingMoreTopVehicles = false;
          });
      }
    },
    getTopVehicles: function (cityId) {
      if (self.isGettingTopVehicles) {
        return;
      }

      self.isGettingTopVehicles = true;

      getTopVehiclesFactory
        .query({
          cidade: cityId,
          limit: self.limit,
          offset: 0,
          vehicleType: self.vehicleType
        })
        .$promise
        .then(function (response) {
          self.topVehicles = response;
          self.vehicles = self.topVehicles;

          self.hasMoreTopVehicles = true;
          self.isGettingTopVehicles = false;
        }, function (reason) {
          $log.error('vehicleService.getTopVehicles', reason);

          self.isGettingTopVehicles = false;
        });
    },
    searchVehicles: function (searchTerm) {
      if (self.isSearchingVehicles) {
        return;
      }

      if (searchTerm) {
        self.isSearchingVehicles = true;

        searchVehiclesFactory
          .query({
            keyword: searchTerm,
            limit: self.limit,
            offset: 0,
            vehicleType: self.vehicleType
          })
          .$promise
          .then(function (response) {
            self.vehicles = response;

            self.isSearchingVehicles = false;
          }, function (reason) {
            $log.error('vehicleService.searchVehicles', reason);

            self.isSearchingVehicles = false;
          });
      } else {
        self.vehicles = self.topVehicles;

        self.isSearchingVehicles = false;
      }
    }
  };

  return self;
}
