angular
  .module('app')
  .config(mouraFacilRoutes);

/** @ngInject */
function mouraFacilRoutes($stateProvider) {
  $stateProvider.state('mouraFacil', {
    url: '/moura-facil',
    component: 'mouraFacil',
    resolve: {
      currentAuth: ['firebaseAuthFactory', function (firebaseAuthFactory) {
        return firebaseAuthFactory.$requireSignIn();
      }]
    }
  });
}
