angular
  .module('app')
  .component('signIn', {
    templateUrl: 'app/components/signIn/signIn.html',
    controller: SignInController
  });

/** @ngInject */
function SignInController($rootScope, $scope, $state, firebaseAuthService) {
  // THIS
  var ctrl = this;

  // SERVICES
  ctrl.firebaseAuthService = firebaseAuthService;

  // OBJECTS
  ctrl.formSignInData = {};

  // STRINGS
  $rootScope.bodyClass = 'sign-in';

  // METHODS
  ctrl.signInWithEmailAndPassword = function (email, password) {
    ctrl.firebaseAuthService
      .signInWithEmailAndPassword(email, password)
      .then(function () {
        ctrl.formSignInData = {};

        $scope.formSignIn.$setPristine();
        $scope.formSignIn.$setUntouched();
      });
  };

  ctrl.signInWithPopupGoogle = function () {
    ctrl.firebaseAuthService.signInWithPopupGoogle();
  };
}
