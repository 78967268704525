/* eslint-disable camelcase */
angular
  .module('app')
  .component('assistencia', {
    templateUrl: 'app/components/assistencia/assistencia.html',
    controller: AssistenciaController
  });

/** @ngInject */
function AssistenciaController($rootScope, $scope, $state, $timeout, $window, toaster, techEvaluationService) {
  // THIS
  var ctrl = this;

  // SERVICES
  ctrl.techEvaluationService = techEvaluationService;

  // STRINGS
  $rootScope.bodyClass = 'assistencia';
  ctrl.pageTitle = 'Validação técnica';

  // FLAGS
  ctrl.modalIsVisible = null;
  ctrl.modalReportIsVisible = null;

  // METHODS
  ctrl.resetTechEvaluation = function () {
    ctrl.techEvaluationService.resetTechEvaluation();

    $scope.formAssistencia.$setPristine();
    $scope.formAssistencia.$setUntouched();

    $timeout(function () {
      $state.go('assistencia');
    });
  };

  ctrl.submitTechEvaluation = function () {
    var timeBeforeTheRequet = moment();

    ctrl.techEvaluationService
      .submitTechEvaluation()
      .then(function () {
        ctrl.resetTechEvaluation();

        ctrl.modalIsVisible = true;
        ctrl.modalReportIsVisible = true;
      }, function (error) {
        var duration = moment.duration(moment().diff(timeBeforeTheRequet));
        var minutes = duration.asMinutes();

        $window.Raven
          .setUserContext({
            email: localStorage.getItem('currentUserEmail')
          })
          .setExtraContext(Object.assign(
            {
              timeOutErroMinutes: minutes
            },
            ctrl.techEvaluationService.techEvaluationData
          ))
          .captureException(error, {
            level: 'error',
            logger: 'app.assistencia.submitTechEvaluation',
            sampleRate: 1
          });

        toaster.pop('error', error.error.error);
      });
  };

  ctrl.toggleReportModal = function () {
    if (ctrl.modalReportIsVisible) {
      ctrl.modalIsVisible = false;
      ctrl.modalReportIsVisible = false;
    } else {
      ctrl.modalIsVisible = true;
      ctrl.modalReportIsVisible = true;
    }
  };
}
