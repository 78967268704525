angular.module('app').service('paymentService', paymentService);

/** @ngInject */
function paymentService(getCardsFlagsFactory) {
  var self = {
    // ARRAYS
    cardsFlags: [],

    // STRINGS
    priceMode: 'normal',

    // FLAGS
    isGettingCardsFlags: null,

    // METHODS
    getCardsFlags: function () {
      if (self.isGettingCardsFlags) {
        return;
      }

      self.isGettingCardsFlags = true;

      getCardsFlagsFactory.query().$promise.then(function (response) {
        self.cardsFlags = response;

        self.isGettingCardsFlags = false;
      });
    }
  };

  self.getCardsFlags();

  return self;
}
