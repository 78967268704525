angular
  .module('app')
  .config(config);

/** @ngInject */
function config($resourceProvider, laddaProvider, FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN, FIREBASE_DATABASE_URL, FIREBASE_MESSAGING_SENDER_ID, FIREBASE_PROJECT_ID, FIREBASE_STORAGE_BUCKET) {
  var firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    databaseURL: FIREBASE_DATABASE_URL,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET
  };

  var laddaConfig = {
    spinnerColor: '#fff',
    spinnerSize: 32,
    style: 'expand-right'
  };

  $resourceProvider
    .defaults
    .stripTrailingSlashes = false;

  laddaProvider.setOption(laddaConfig);

  firebase.initializeApp(firebaseConfig);
}
