/* eslint-disable camelcase */
angular
  .module('app')
  .service('techEvaluationService', techEvaluationService);

/** @ngInject */
function techEvaluationService($log, $window, $q, toaster, techEvaluationFactory) {
  var self = {
    // OBJECTS
    techEvaluationData: {
      battery_model: '',
      charged_recharge: false,
      charged_tech_evaluation: false,
      collection_date: new Date(),
      delivery_date: new Date(),
      description_defect: 'bateria_nao_encontrada',
      fab_code: '',
      shortId: '',
      warranty: '',
      was_there_a_sale: false
    },

    // FLAGS
    isSubmittingTechEvaluation: false,

    // METHODS
    resetTechEvaluation: function () {
      self.techEvaluationData = {};

      self.techEvaluationData = {
        battery_model: '',
        charged_recharge: false,
        charged_tech_evaluation: false,
        collection_date: new Date(),
        delivery_date: new Date(),
        description_defect: 'bateria_nao_encontrada',
        fab_code: '',
        shortId: '',
        warranty: '',
        was_there_a_sale: false
      };
    },

    submitTechEvaluation: function () {
      if (self.isSubmittingTechEvaluation) {
        return;
      }

      self.isSubmittingTechEvaluation = true;

      var defer = $q.defer();

      var timeBeforeTheRequet = moment();

      self.techEvaluationData.battery_model = self.techEvaluationData.battery_model.toUpperCase();
      self.techEvaluationData.collection_date = new Date(self.techEvaluationData.collection_date).toISOString().split('T')[0];
      self.techEvaluationData.delivery_date = new Date(self.techEvaluationData.delivery_date).toISOString().split('T')[0];
      self.techEvaluationData.fab_code = self.techEvaluationData.fab_code.toUpperCase();
      self.techEvaluationData.shortId = self.techEvaluationData.shortId.toUpperCase();

      if (
        self.techEvaluationData.description_defect === 'curto_circuito' ||
        self.techEvaluationData.description_defect === 'descarga_acelerada' ||
        self.techEvaluationData.description_defect === 'desligada' ||
        self.techEvaluationData.description_defect === 'migracao' ||
        self.techEvaluationData.description_defect === 'sem_uso_com_falha_na_fabricacao' ||
        self.techEvaluationData.description_defect === 'vazamento_pelo_suspiro_autorizado' ||
        self.techEvaluationData.description_defect === 'vazamento'
      ) {
        self.techEvaluationData.warranty = 'Procedente';
      } else {
        self.techEvaluationData.warranty = 'Improcedente';
      }

      techEvaluationFactory
        .save({shortId: self.techEvaluationData.shortId}, self.techEvaluationData)
        .$promise
        .then(function () {
          self.isSubmittingTechEvaluation = false;
          self.triedToOrder = false;

          defer.resolve();
        }, function (reason) {
          var duration = moment.duration(moment().diff(timeBeforeTheRequet));
          var minutes = duration.asMinutes();

          $log.error('techEvaluationService.submitTechEvaluation', reason);

          $window.Raven
            .setUserContext({
              email: localStorage.getItem('currentUserEmail')
            })
            .setExtraContext(Object.assign(
              {
                timeOutErroMinutes: minutes
              },
              self.techEvaluationData
            ))
            .captureException(reason, {
              level: 'error',
              logger: 'techEvaluationService.submitTechEvaluation',
              sampleRate: 1
            });

          toaster.pop('error', 'Ops! Não existe um pedido com o código que você informou. Por favor, verifique tente novamente.');

          self.isSubmittingTechEvaluation = false;
          self.triedToOrder = true;
        });

      return defer.promise;
    }
  };

  return self;
}
