angular
  .module('app')
  .service('cityService', cityService);

/** @ngInject */
function cityService($log, getTopCitiesFactory, searchCitiesFactory) {
  var self = {
    // ARRAYS
    assistenciaFacilCities: [],
    cities: [],
    topCities: [],

    // OBJECTS
    selectedCity: {},

    // NUMBERS
    limit: 64,

    // FLAGS
    hasMoreTopCities: true,
    ignoreDifferentCity: null,
    isGettingMoreTopCities: null,
    isGettingTopCities: null,
    isSearchingCities: null,

    // METHODS
    filterAndFillAssistenciaFacilCities: function (cities) {
      self.assistenciaFacilCities = cities.filter(function (city) {
        return city.id === '5aabce20621df30004456332' || // Aracaju, SE
               city.id === '5b2a8bf0c6e43700049bb67d' || // Caruaru, PE
               city.id === '599ec45ae4b02d300e9902af' || // Fortaleza, CE
               city.id === '59105456e4b00d944a6ac1f9' || // Jaboatão dos Guararapes, PE
               city.id === '588735ace4b05db85313165e' || // João Pessoa, PB
               city.id === '592c12c4e4b038f5845ee5c8' || // Lauro de Freitas, BA
               city.id === '5909e817e4b02e58b9ce447f' || // Olinda, PE
               city.id === '5f04b744acb7970003ca197a' || // Paulista, PE
               city.id === '5e84f678918dfb000329d1d3' || // Paulo Afonso, BA
               city.id === '5c8a3e9f99f2c900030226b8' || // Petrolina, PE
               city.id === '5880c262e4b0e1abc41f94b6' || // Recife, PE
               city.id === '592438f1e4b054e8e0620f9e' || // Salvador, BA
               city.id === '5b290b7c88a5640004d7f4ea' || // São Luís, MA
               city.id === '5d55b8b71f5d010003d7e640' || // Sinop, MT
               city.id === '5ce67ad999a06900033b7e2a';   // Teresina, PI
      });

      self.assistenciaFacilCities = self.assistenciaFacilCities.map(function (city) {
        city.cityNormalized = city.city.normalize('NFKD').replace(/[\u0300-\u036F]/g, '');

        return city;
      });
    },
    getMoreTopCities: function () {
      if (self.isGettingMoreTopCities) {
        return;
      }

      var partner = '';

      if (self.hasMoreTopCities) {
        self.isGettingMoreTopCities = true;

        getTopCitiesFactory
          .query({
            limit: self.limit,
            offset: self.topCities.length,
            partner: partner
          })
          .$promise
          .then(function (response) {
            self.topCities = self.topCities.concat(response);
            self.cities = self.topCities;

            self.filterAndFillAssistenciaFacilCities(self.topCities);

            if (response.length === 0) {
              self.hasMoreTopCities = false;
            }

            self.isGettingMoreTopCities = false;
          }, function (reason) {
            $log.error('cityService.getMoreTopCities', reason);

            self.isGettingMoreTopCities = false;
          });
      }
    },
    getTopCities: function (force, leadSource) {
      if (!force && sessionStorage.getItem('topCities')) {
        self.topCities = angular.fromJson(sessionStorage.getItem('topCities'));

        return;
      }

      if (self.isGettingTopCities) {
        return;
      }

      self.isGettingTopCities = true;

      var partner;

      if (leadSource) {
        partner = leadSource.toLowerCase();

        if (
          partner === 'adwords' ||
          partner === 'googlemerchant' ||
          partner === 'branding' ||
          partner === 'branding_balneariocomboriu' ||
          partner === 'branding_blumenau' ||
          partner === 'branding_brasilia' ||
          partner === 'branding_criciuma' ||
          partner === 'branding_curitiba' ||
          partner === 'branding_florianopolis' ||
          partner === 'branding_fortaleza' ||
          partner === 'branding_niteroi' ||
          partner === 'branding_recife' ||
          partner === 'branding_riodejaneiro' ||
          partner === 'branding_saojose' ||
          partner === 'branding_saoluis' ||
          partner === 'branding_saopaulo' ||
          partner === 'olx' ||
          partner === 'organico' ||
          partner === 'portal_v5' ||
          partner === 'portalbanner' ||
          partner === 'portalwidget' ||
          partner === 'mourafacil'
        ) {
          partner = '';
        }
      }

      getTopCitiesFactory
        .query({
          limit: self.limit,
          offset: 0,
          partner: partner
        })
        .$promise
        .then(function (response) {
          self.topCities = response.map(function (city) {
            city.cityNormalized = city.city.normalize('NFKD').replace(/[\u0300-\u036F]/g, '');

            return city;
          });

          self.cities = self.topCities;

          self.filterAndFillAssistenciaFacilCities(self.topCities);

          sessionStorage.setItem('topCities', angular.toJson(self.topCities));

          self.isGettingTopCities = false;
        }, function (reason) {
          // if (leadSource) {
          //   toaster.pop('error', 'Não existem cidades atreladas a parceria "' + leadSource + '".');
          // }

          $log.error('cityService.getTopCities', reason);

          self.isGettingTopCities = false;
        });
    },
    searchCities: function (searchTerm) {
      if (self.isSearchingCities) {
        return;
      }

      if (searchTerm) {
        self.isSearchingCities = true;

        searchCitiesFactory
          .query({
            keyword: searchTerm,
            limit: self.limit,
            offset: 0
          })
          .$promise
          .then(function (response) {
            self.cities = response;

            self.filterAndFillAssistenciaFacilCities(response);

            self.isSearchingCities = false;
          }, function (reason) {
            $log.error('cityService.searchCities', reason);

            self.isSearchingCities = false;
          });
      } else {
        self.cities = self.topCities;

        self.filterAndFillAssistenciaFacilCities(self.topCities);

        self.isSearchingCities = false;
      }
    }
  };

  self.getTopCities(false);

  return self;
}
