/* eslint-disable camelcase */
angular
  .module('app')
  .factory('orderFactory', orderFactory);

/** @ngInject */
function orderFactory($resource, MOURA_FACIL_DIGITAL_SERVICES_API) {
  return $resource(MOURA_FACIL_DIGITAL_SERVICES_API + '/orders/:order_id/:action/',
    {
      order_id: '@order_id',
      action: '@action'
    },
    {},
    {
      stripTrailingSlashes: false
    }
  );
}
