angular
  .module('app')
  .service('firebaseAuthService', firebaseAuthService);

/** @ngInject */
function firebaseAuthService($rootScope, $log, $q, $state, Slug, toaster, orderService, firebaseAuthFactory, callMonitorService) {
  var self = {
    // OBJECTS
    currentUser: {},

    // FLAGS
    isAdmin: false,
    isCheckingUserLevel: false,
    isCreatingUser: false,
    isLogged: false,
    isSendingPasswordResetEmail: false,
    isSigningInWidhEmailAndPassword: false,
    isSigningInWithPopupGoogle: false,
    isSigningOut: false,
    isUpdatingUserEmail: false,
    isUpdatingUserPassword: false,

    // SERVICES
    orderService: orderService,
    callMonitorService: callMonitorService,

    // METHODS
    checkUserLevel: function () {
      if (self.isCheckingUserLevel) {
        return;
      }

      self.isCheckingUserLevel = true;

      var defer = $q.defer();

      firebase
        .database()
        .ref('/users/' + Slug.slugify(localStorage.getItem('currentUserEmail')))
        .once('value')
        .then(function (response) {
          self.isAdmin = response.val().admin;

          self.isCheckingUserLevel = false;

          defer.resolve();
        });

      return defer.promise;
    },
    createUser: function (data) {
      if (self.isCreatingUser) {
        return;
      }

      self.isCreatingUser = true;

      var defer = $q.defer();

      if (data.id === 0) {
        data.id = self.generateNewID();
      }

      firebaseAuthFactory
        .$createUserWithEmailAndPassword(data.email, data.password)
        .then(function (response) {
          firebase
            .database()
            .ref('users/' + Slug.slugify(data.email))
            .set({
              admin: data.admin,
              email: data.email,
              id: data.id,
              username: Slug.slugify(data.email)
            }, function (reason) {
              if (reason) {
                toaster.pop('error', 'Ops! Ocorreu um erro. Por favor, tente novamente.');

                $log.error('firebaseAuthService.createUser', reason);

                self.isCreatingUser = false;
              } else {
                toaster.pop('success', 'Ok. Usuário com o email "' + response.email + '" criado!');

                self.isCreatingUser = false;

                defer.resolve();
              }
            });
        }, function (reason) {
          if (reason.code === 'auth/email-already-in-use') {
            toaster.pop('error', 'Ops! Já existe um usuário cadastrado com esse email.');
          } else {
            toaster.pop('error', 'Ops! Ocorreu um erro durante a criação do usuário. Por favor, tente novamente.');
          }

          $log.error('firebaseAuthService.createUser', reason);

          self.isCreatingUser = false;
        });

      return defer.promise;
    },
    generateNewID: function () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 || 0;
        var v = c === 'x' ? r : (r & 0x3 || 0x8);

        return v.toString(16);
      });
    },
    resetPassword: function (email) {
      if (self.isSendingPasswordResetEmail) {
        return;
      }

      self.isSendingPasswordResetEmail = true;

      var defer = $q.defer();

      firebaseAuthFactory
        .$sendPasswordResetEmail(email)
        .then(function () {
          toaster.pop('info', 'Email de redefinição de senha enviado!');

          self.isSendingPasswordResetEmail = false;

          defer.resolve();
        })
        .catch(function (reason) {
          if (reason.code === 'auth/user-not-found') {
            toaster.pop('error', 'Não existe usuário cadastrado com o email informado.');
          }

          $log.error('firebaseAuthService.resetPassword()', reason);

          self.isSendingPasswordResetEmail = false;
        });

      return defer.promise;
    },
    signInWithEmailAndPassword: function (email, password) {
      if (self.isSigningInWithEmailAndPassword) {
        return;
      }

      self.isSigningInWithEmailAndPassword = true;

      var defer = $q.defer();

      firebaseAuthFactory
        .$signInWithEmailAndPassword(email, password)
        .then(function (response) {
          self.currentUser = response;

          $rootScope.attendantEmail = email;

          localStorage.setItem('currentUserEmail', email);

          if (
            email === 'mourafacil13@speedmais.com.br' ||
            email === 'mourafacil16@speedmais.com.br' ||
            email === 'mourafacil18@speedmais.com.br' ||
            email === 'mourafacil8@speedmais.com.br'
          ) {
            self.orderService.orderData.origin = 'whatsapp';
          }

          // self.checkUserLevel();

          self.isSigningInWithEmailAndPassword = false;

          defer.resolve();

          $state.go('mouraFacil');
        }, function (reason) {
          if (reason.code === 'auth/invalid-email') {
            toaster.pop('error', 'O email informado não é válido.');
          } else if (reason.code === 'auth/network-request-failed') {
            toaster.pop('error', 'Não foi possível se conectar com o servidor. Por favor, tente novamente daqui a alguns instantes.');
          } else if (reason.code === 'auth/too-many-requests') {
            toaster.pop('error', 'Acesso temporariamente bloqueado a partir deste dispositivo. Por favor, tente novamente mais tarde.');
          } else if (reason.code === 'auth/user-not-found') {
            toaster.pop('error', 'Não existe usuário com o email informado.');
          } else if (reason.code === 'auth/wrong-password') {
            toaster.pop('error', 'A senha informada não existe.');
          }

          // localStorage.clear();
          // sessionStorage.clear();

          $log.error('firebaseAuthService.signInWithEmailAndPassword', reason);

          self.isSigningInWithEmailAndPassword = false;
        });

      return defer.promise;
    },
    signInWithPopupGoogle: function () {
      if (self.isSigningInWithPopupGoogle) {
        return;
      }

      self.isSigningInWithPopupGoogle = true;

      firebaseAuthFactory
        .$signInWithPopup('google')
        .then(function (response) {
          self.currentUser = response.user;

          localStorage.setItem('currentUserEmail', self.currentUser.email);

          toaster.pop('success', 'Olá, bem-vindo(a) de volta!');

          self.isSigningInWithPopupGoogle = false;
        })
        .catch(function (reason) {
          $log.error('firebaseAuthService.signInWithPopupGoogle', reason);

          toaster.pop('error', reason.message);

          self.isSigningInWithPopupGoogle = false;
        });
    },
    signOut: function () {
      if (self.isSigningOut) {
        return;
      }

      self.isSigningOut = true;

      self.callMonitorService.closeEventConnection();

      sessionStorage.clear();

      firebaseAuthFactory
        .$signOut()
        .then(function () {
          // localStorage.clear();
          // sessionStorage.clear();

          self.isSigningOut = false;

          $state.go('signIn');
        }, function (reason) {
          $log.error('firebaseAuthService.signOut', reason);

          self.isSigningOut = false;
        });
    },
    updateUserEmail: function (email) {
      if (self.isUpdatingUserEmail) {
        return;
      }

      self.isUpdatingUserEmail = true;

      var defer = $q.defer();

      firebaseAuthFactory
        .$updateEmail(email)
        .then(function () {
          toaster.pop('success', 'Ok. Email redefinido!');

          self.isUpdatingUserEmail = false;

          defer.resolve();
        }, function (reason) {
          toaster.pop('error', 'Ops! Ocorreu um erro durante a redefinição do email. Por favor, tente novamente.');

          $log.error('firebaseAuthService.updateUserEmail', reason);

          self.isUpdatingUserEmail = false;
        });

      return defer.promise;
    },
    updateUserPassword: function (password) {
      if (self.isUpdatingUserPassword) {
        return;
      }

      self.isUpdatingUserPassword = true;

      var defer = $q.defer();

      firebaseAuthFactory
        .$updatePassword(password)
        .then(function () {
          toaster.pop('success', 'Ok. Senha redefinida!');

          self.isUpdatingUserPassword = false;

          defer.resolve();
        }, function (reason) {
          if (reason.code === 'auth/requires-recent-login') {
            toaster.pop('error', 'Ops! Esta operação é delicada e requer autenticação recente. Logue novamente antes de tentar outra vez.');
          } else {
            toaster.pop('error', 'Ops! Ocorreu um erro durante a redefinição da senha. Por favor, tente novamente.');
          }

          $log.error('firebaseAuthService.updateUserPassword', reason);

          self.isUpdatingUserPassword = false;
        });

      return defer.promise;
    }
  };

  // CALLS
  firebaseAuthFactory.$onAuthStateChanged(function (currentUser) {
    var currentState = $state.$current.name;

    if (currentUser) {
      // self.checkUserLevel();

      self.isLogged = true;
    } else {
      self.isLogged = false;

      $state.go('signIn');
    }

    if (currentUser && currentState === 'signIn') {
      $state.go('mouraFacil');
    }
  });

  return self;
}
