angular
  .module('app')
  .factory('getBatteryFactory', getBatteryFactory);

/** @ngInject */
function getBatteryFactory($resource, MOURA_SEARCH_API, MOURA_SEARCH_API_KEY) {
  return $resource(MOURA_SEARCH_API + '/pricecheck', {
    modelo: '@modelo',
    cidade: '@cidade',
    partner: '@partner'
  }, {
    get: {
      headers: {
        'x-api-key': MOURA_SEARCH_API_KEY
      },
      method: 'GET'
    }
  });
}
