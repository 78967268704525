angular
  .module('app')
  .component('mouraFacil', {
    templateUrl: 'app/components/mouraFacil/mouraFacil.html',
    controller: MouraFacilController
  });

/** @ngInject */
function MouraFacilController($rootScope, $scope, $document, $interval, $log, $state, $timeout, $window, $firebaseObject, $sessionStorage, clipboard, NgMap, toaster, getAddressFactory, batteryService, callMonitorService, cityService, extraBatteryService, firebaseAuthService, localizationService, openHoursService, orderService, paymentService, retailersService, vehicleService) {
  // THIS
  var ctrl = this;

  // SERVICES
  ctrl.batteryService = batteryService;
  ctrl.callMonitorService = callMonitorService;
  ctrl.cityService = cityService;
  ctrl.extraBatteryService = extraBatteryService;
  ctrl.firebaseAuthService = firebaseAuthService;
  ctrl.localizationService = localizationService;
  ctrl.openHoursService = openHoursService;
  ctrl.orderService = orderService;
  ctrl.paymentService = paymentService;
  ctrl.retailersService = retailersService;
  ctrl.vehicleService = vehicleService;

  // ARRAYS
  ctrl.openHours = [];
  ctrl.origins = [
    {
      label: 'GBM',
      origin: 'origin_gbm'
    },
    {
      label: 'Mercado Livre Classic',
      origin: 'mercadolivre'
    },
    {
      label: 'Mercado Livre Premium',
      origin: 'origin_mercadolivrepremium'
    },
    {
      label: 'OLX',
      origin: 'origem_olx'
    },
    {
      label: 'Telefone',
      origin: 'phone'
    },
    {
      label: 'WhatsApp',
      origin: 'whatsapp'
    }
  ];

  // STRINGS
  $rootScope.bodyClass = 'moura-facil';
  ctrl.pageTitle = 'Criar pedido';
  ctrl.today = '';
  ctrl.mercadoLivrePartner = '';

  // BOOLEANS
  $scope.isImmediateDeliveryAvailable = true;

  // FLAGS
  ctrl.checkedVoucherUber = null;
  ctrl.focusIsOnCity = null;
  ctrl.focusIsOnExtraBattery = null;
  ctrl.focusIsOnVehicle = null;
  ctrl.modalBatteryDetailsIsVisible = null;
  ctrl.modalExtraBatteryDetailsIsVisible = null;
  ctrl.modalIsVisible = null;
  ctrl.modalMapIsVisible = null;
  ctrl.modalPreviousClientIsVisible = null;
  ctrl.modalResetIsVisible = null;
  ctrl.modalResumeIsVisible = null;
  ctrl.modalVoucherUberIsVisible = null;
  ctrl.voucherUberCity = null;

  // METHODS
  // METHODS - Address
  ctrl.addressAutocomplete = function () {
    var defaultBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(ctrl.localizationService.selectedCityBounds.northeast.latitude, ctrl.localizationService.selectedCityBounds.northeast.longitude),
      new google.maps.LatLng(ctrl.localizationService.selectedCityBounds.southwest.latitude, ctrl.localizationService.selectedCityBounds.southwest.longitude)
    );

    var input = $document[0].getElementById('form-moura-facil-address');

    var options = {
      bounds: defaultBounds,
      componentRestrictions: {
        country: 'br'
      }
    };

    var autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.addListener('places_changed', function () {
      var places = autocomplete.getPlaces();

      if (places.length === 0) {
        return;
      }

      var placeStreet;
      var placeNumber;
      var placeNeighborhood;
      var placeCity;
      var placeState;
      var placeLatitude;
      var placeLongitude;

      for (var i = 0; i < places[0].address_components.length; ++i) {
        var component = places[0].address_components[i];

        if (component.types.indexOf('route') > -1) {
          placeStreet = component.long_name;
        } else if (component.types.indexOf('street_number') > -1) {
          placeNumber = component.long_name;
        } else if (component.types.indexOf('sublocality') > -1) {
          placeNeighborhood = component.long_name;
        } else if (component.types.indexOf('locality') > -1) {
          placeCity = component.long_name;
        } else if (component.types.indexOf('administrative_area_level_2') > -1) {
          placeCity = component.long_name;
        } else if (component.types.indexOf('administrative_area_level_1') > -1) {
          placeState = component.short_name;
        }

        placeLatitude = places[0].geometry.location.lat();
        placeLongitude = places[0].geometry.location.lng();
      }

      if (ctrl.cityService.selectedCity.city === placeCity) {
        ctrl.orderService.orderData.address.street = placeStreet;
        ctrl.orderService.orderData.address.number = placeNumber;
        ctrl.orderService.orderData.address.neighborhood = placeNeighborhood;
        ctrl.orderService.orderData.address.city = placeCity;

        if (placeState) {
          ctrl.orderService.orderData.address.state = placeState;
        }

        ctrl.orderService.orderData.address.latitude = placeLatitude;
        ctrl.orderService.orderData.address.longitude = placeLongitude;

        ctrl.getTopVehicles(ctrl.cityService.selectedCity.id);

        ctrl.localizationService.addressIsFromAnotherCity = false;
      } else {
        ctrl.localizationService.addressIsFromAnotherCity = true;
      }

      getRetailersMouraFacil(placeLatitude, placeLongitude);
    });
  };

  ctrl.checkGoogleMapsAgain = function () {
    if (ctrl.orderService.orderData.address.number.length === 0) {
      return;
    }

    var address = ctrl.orderService.orderData.address.street + ', ' +
                  ctrl.orderService.orderData.address.number + ' - ' +
                  ctrl.orderService.orderData.address.city + ', ' +
                  ctrl.orderService.orderData.address.state;

    ctrl.getGeocode(address);
  };

  ctrl.getAddress = function () {
    if (
      ctrl.localizationService.isGettingAddress ||
      (
        ctrl.orderService.orderData.address.zipCode &&
        ctrl.orderService.orderData.address.zipCode.length !== 8
      )
    ) {
      return;
    }

    if (
      ctrl.orderService.orderData.address.zipCode &&
      ctrl.orderService.orderData.address.zipCode.length === 8
    ) {
      ctrl.localizationService.isGettingAddress = true;

      getAddressFactory
        .get({
          zipcode: ctrl.orderService.orderData.address.zipCode
        })
        .$promise
        .then(function (response) {
          if (ctrl.cityService.topCities.length > 0) {
            for (var i = 0; i < ctrl.cityService.topCities.length; i++) {
              if (ctrl.cityService.topCities[i].city === response.localidade) {
                if (ctrl.cityService.selectedCity.city === response.localidade) {
                  ctrl.orderService.orderData.address.city = response.localidade;
                  ctrl.orderService.orderData.address.neighborhood = response.bairro;
                  ctrl.orderService.orderData.address.state = response.uf;
                  ctrl.orderService.orderData.address.street = response.endereco;

                  ctrl.getGeocode(response.endereco + ', ' + response.localidade + ' - ' + response.uf);

                  ctrl.localizationService.addressIsFromAnotherCity = false;
                } else {
                  toaster.pop('error', 'O endereço informado é de outra cidade.');

                  ctrl.localizationService.addressIsFromAnotherCity = true;
                }
              }
            }
          }

          ctrl.localizationService.isGettingAddress = false;
        }, function (reason) {
          $log.error('ctrl.getAddress', reason);

          toaster.pop('error', 'O CEP informado não retornou dados válidos.');

          ctrl.localizationService.isGettingAddress = false;
        });
    }
  };

  ctrl.getGeocode = function (address) {
    if (ctrl.localizationService.isGettingGeocode) {
      return;
    }

    ctrl.localizationService.isGettingGeocode = true;

    var geocoder = new google.maps.Geocoder();

    geocoder.geocode({
      address: address
    }, function (results, status) {
      if (status === 'OK' && results[0].geometry.bounds) {
        var bounds = results[0].geometry.bounds;
        var boundsLatitude = bounds[Object.keys(bounds)[0]];
        var boundsLongitude = bounds[Object.keys(bounds)[1]];

        ctrl.localizationService.selectedCityBounds = {
          northeast: {
            latitude: boundsLongitude[Object.keys(boundsLongitude)[0]],
            longitude: boundsLatitude[Object.keys(boundsLatitude)[0]]
          },
          southwest: {
            latitude: boundsLongitude[Object.keys(boundsLongitude)[1]],
            longitude: boundsLatitude[Object.keys(boundsLatitude)[1]]
          }
        };

        ctrl.orderService.orderData.address.latitude = results[0].geometry.location.lat();
        ctrl.orderService.orderData.address.longitude = results[0].geometry.location.lng();

        ctrl.localizationService.isGettingGeocode = false;
      } else if (status === 'OK' && !results[0].geometry.bounds) {
        ctrl.orderService.orderData.address.latitude = results[0].geometry.location.lat();
        ctrl.orderService.orderData.address.longitude = results[0].geometry.location.lng();

        ctrl.localizationService.isGettingGeocode = false;
      } else {
        $log.error('ctrl.getGeocode', results);

        ctrl.localizationService.isGettingGeocode = false;
      }

      if (status === 'ERROR' || status === 'REQUEST_DENIED' || status === 'UNKNOWN_ERROR') {
        toaster.pop('error', 'Ocorreu um erro de comunicação com o serviço de geolocalização do Google. Por favor, tente novamente.');
      } else if (status === 'INVALID_REQUEST' || status === 'ZERO_RESULTS') {
        toaster.pop('error', 'Não foi possível identificar as coordenadas usando apenas a cidade informada.');
      }
    });
  };

  ctrl.resetAddress = function () {
    ctrl.orderService.orderData.address.street = '';
    ctrl.orderService.orderData.address.number = '';
    ctrl.orderService.orderData.address.neighborhood = '';
    ctrl.orderService.orderData.address.zipCode = '';
    ctrl.orderService.orderData.address.latitude = '';
    ctrl.orderService.orderData.address.longitude = '';

    ctrl.localizationService.addressIsFromAnotherCity = false;
    ctrl.cityService.ignoreDifferentCity = false;
  };

  // METHODS - Batteries
  function resetBattery() {
    ctrl.batteryService.selectedBattery = {};
    ctrl.orderService.orderData.battery = {};
  }

  ctrl.getBattery = function () {
    if (
      !ctrl.cityService.selectedCity.id ||
      !ctrl.vehicleService.selectedVehicle.id
    ) {
      return;
    }

    this.verifyPartner(ctrl.orderService.orderData.leadSource);

    ctrl.batteryService
      .getBattery(
        ctrl.cityService.selectedCity.id,
        ctrl.vehicleService.selectedVehicle.id,
        ctrl.mercadoLivrePartner || ctrl.orderService.orderData.leadSource
      )
      .then(function () {
        ctrl.orderService.batteryBrand = 'moura';

        ctrl.orderService.orderData.battery =
          ctrl.batteryService.selectedBattery;

        if (ctrl.batteryService.selectedBattery.discount === 0) {
          ctrl.paymentService.priceMode = 'normal';
        } else {
          ctrl.paymentService.priceMode = 'discount';
        }
      });
  };

  // METHODS - Calls
  function fillFormWithCallData(response) {
    sessionStorage.setItem('mfaCallDataCallID', ctrl.callMonitorService.callData.id);
    sessionStorage.setItem('mfaCallDataCallLeadSource', ctrl.callMonitorService.callData.lead_source);

    $rootScope.attendantName = response.attendant_name;
    $rootScope.attendantEmail = response.attendant_email;

    ctrl.orderService.orderData.customer.phone = response.client_number;
    ctrl.orderService.orderData.leadSource = response.lead_source;
    ctrl.orderService.orderData.leadSourceDetails = response.lead_source_details;
    ctrl.orderService.orderData.customer.origin = response.lead_source;

    if (response.lead_source === 'olx') {
      ctrl.orderService.orderData.origin = 'origem_olx';
    }
  }

  function handleDifferenceCallID(response) {
    var mfaWantsToPreserve = sessionStorage.getItem('mfaWantsToPreserve') === 'true';
    var mfaCallDataDiscard = sessionStorage.getItem('mfaCallDataDiscard') === 'true';

    if (!mfaWantsToPreserve) {
      ctrl.showPreviousClientModal();
    } else if (!mfaWantsToPreserve && mfaCallDataDiscard) {
      fillFormWithCallData(response);
    }
  }

  function getCallData() {
    if (ctrl.orderService.orderData.origin !== 'phone') {
      return;
    }

    var call = ctrl.callData || {};

    if (call.status === 'TRANSFER') {
      ctrl.resetOrder();

      return toaster.pop('success', 'Sua ligação foi transferida com sucesso.');
    }

    if (call.status === 'IN_PROGRESS') {
      var previousCallID = sessionStorage.getItem('mfaCallDataCallID');
      var isNotSameCallID = call.id !== previousCallID;

      if (previousCallID && isNotSameCallID) {
        return handleDifferenceCallID(call, previousCallID);
      }

      fillFormWithCallData(call);
    }
  }

  // METHODS - Cities
  function getCityZone() {
    ctrl.openHoursService.getZone(ctrl.cityService.selectedCity.city, ctrl.cityService.selectedCity.state)
    .then(function (response) {
      ctrl.orderService.orderData.city.zone = response;
    });
  }

  function checkVoucherUberCity() {
    if (
      ctrl.orderService.orderData.city.id === '5c389be2377f330004bb7d68' || // Curitiba
      ctrl.orderService.orderData.city.id === '59f22eaee4b0b4e81dd62379'    // Rio de Janeiro
    ) {
      ctrl.voucherUberCity = true;
    } else {
      ctrl.voucherUberCity = false;
    }
  }

  ctrl.blurCity = function () {
    $timeout(function () {
      if (ctrl.cityService.selectedCity.city) {
        ctrl.orderService.orderData.address.city = ctrl.cityService.selectedCity.city;
      }

      ctrl.focusIsOnCity = false;
    }, 300);
  };

  ctrl.getMoreTopCities = function () {
    ctrl.cityService.getMoreTopCities();
  };

  ctrl.setFocusOnCity = function () {
    ctrl.orderService.orderData.address.city = '';

    ctrl.focusIsOnCity = true;
  };

  ctrl.resetCity = function () {
    ctrl.vehicleService.topVehicles = [];

    ctrl.cityService.selectedCity = {};
    ctrl.orderService.orderData.address = {};
    ctrl.orderService.orderData.city = {};

    ctrl.orderService.orderData.dst = null;

    resetBattery();

    ctrl.resetExtraBattery();
    ctrl.resetVehicle();

    ctrl.cityService.ignoreDifferentCity = false;
    ctrl.localizationService.addressIsFromAnotherCity = false;
  };

  ctrl.setCity = function (selectedCity, alert) {
    if (
      alert &&
      ctrl.cityService.selectedCity.id &&
      ctrl.cityService.selectedCity.city !== selectedCity.city
    ) {
      toaster.pop('info', 'Cidade mudou de "' + ctrl.cityService.selectedCity.city + '" para "' + selectedCity.city + '".');
    }

    ctrl.extraBatteryService.extraBatteries = [];

    $sessionStorage.isOpen = false;

    ctrl.isOpen = $sessionStorage.isOpen;

    ctrl.resetAddress();
    ctrl.resetExtraBattery();

    ctrl.cityService.selectedCity = selectedCity;

    ctrl.orderService.orderData.address.city = selectedCity.city;
    ctrl.orderService.orderData.address.state = selectedCity.state;
    ctrl.orderService.orderData.city = selectedCity;
    ctrl.orderService.orderData.city.dst = selectedCity.dst;
    ctrl.orderService.orderData.installments = selectedCity.instalments;

    getCityZone();
    checkVoucherUberCity();

    ctrl.getExtraBatteries();
    ctrl.getGeocode(selectedCity.city + ', ' + selectedCity.state);
    ctrl.getOpenDays();
    ctrl.getOpenHours();
    ctrl.getTopVehicles(selectedCity.id);

    ctrl.getBattery();
  };

  // METHODS - Exceptional Hours
  // METHODS - Exceptional Hours - Intervals
  function joinIntervals(intervals) {
    var joinedIntervals = [];
    var currentInterval = intervals[0];

    for (var i = 1; i < intervals.length; i++) {
      var intervalStartHour = Number(intervals[i].start.replace(':', ''));
      var intervalEndHour = Number(intervals[i].end.replace(':', ''));
      var currentIntervalEndHour = Number(currentInterval.end.replace(':', ''));

      if (
        currentIntervalEndHour >= intervalStartHour ||
        intervalStartHour - currentIntervalEndHour <= 100 ||
        intervalStartHour === intervalEndHour
      ) {
        currentInterval.end = intervals[i].end;
      } else {
        joinedIntervals.push(currentInterval);
        currentInterval = intervals[i];
      }
    }

    joinedIntervals.push(currentInterval);

    return joinedIntervals;
  }

  function createIntervalOptions(intervals) {
    if (!intervals.length) {
      return [];
    }

    intervals = joinIntervals(intervals);

    var intervalsWithRules = [];

    for (var i = 0; i < intervals.length; i++) {
      var startHour = Number(intervals[i].start.split(':')[0]);
      var startMinute = Number(intervals[i].start.split(':')[1]);
      var endHour = Number(intervals[i].end.split(':')[0]);
      var endMinute = Number(intervals[i].end.split(':')[1]);

      while (!(startHour === endHour && startMinute === endMinute)) {
        startMinute += 30;
        if (startMinute === 60) {
          startMinute = 0;
          startHour += 1;
        }
        intervalsWithRules.push({hour: startHour, minute: startMinute});
      }

      if (endMinute === 30) {
        endMinute = 0;
        endHour += 1;
      } else {
        endMinute += 30;
      }
      intervalsWithRules.push({hour: endHour, minute: endMinute});
    }

    return intervalsWithRules;
  }

  function removeIntervalOptions(availableHours, intervalHours) {
    function isInInterval(hour, minute, interval) {
      return hour === interval.hour && minute === interval.minute;
    }

    var filteredAvailableHours = availableHours.filter(function (availableHour) {
      return !intervalHours.some(function (intervalHour) {
        return isInInterval(availableHour.hour, availableHour.minute, intervalHour);
      });
    });

    return filteredAvailableHours;
  }

  // METHODS - Exceptional Hours - Hours
  function createAvailableHoursOptions(day) {
    var availableHours = [];
    var dayHourOpens;
    var dayHourEnds;

    if (day && day.hours) {
      dayHourOpens = Number(day.hours.opens.split(':')[0]) + 1;
      dayHourEnds = Number(day.hours.ends.split(':')[0]) + 1;

      for (var i = dayHourOpens; i <= dayHourEnds; i++) {
        availableHours.push({
          hour: i === 24 ? 0 : i,
          minute: 0
        });

        if (i < dayHourEnds || (i === dayHourEnds && day.hours.ends.split(':')[1] === '30')) {
          availableHours.push({
            hour: i,
            minute: 30
          });
        }
      }
    }

    if (
      day.hours.intervals.length &&
      dayHourOpens === Number(day.hours.intervals[0].start.split(':')[0])
    ) {
      availableHours.shift();
    }

    return availableHours;
  }

  function addTimeDifferenceRuleForTodayOptions(availableHours, day) {
    var currentDate = new Date();
    var systemCurrentHour = currentDate.getHours();
    var systemCurrentMinute = currentDate.getMinutes();
    var minimumDifference = 50;
    var isToday = day === 'hoje';

    function isTimeDifferenceValid(availableHour) {
      var hourDifference =
        availableHour.hour === 0 ? 24 : availableHour.hour - systemCurrentHour;
      var minuteDifference = availableHour.minute - systemCurrentMinute;
      var totalDifference = (hourDifference * 60) + minuteDifference;

      return totalDifference >= minimumDifference;
    }

    if (isToday) {
      return availableHours.filter(isTimeDifferenceValid);
    }

    return availableHours;
  }

  // METHODS - Extra Batteries
  ctrl.blurExtraBattery = function () {
    $timeout(function () {
      if (ctrl.extraBatteryService.selectedExtraBattery.id) {
        ctrl.orderService.orderData.extraBattery = ctrl.extraBatteryService.selectedExtraBattery.amper + 'Ah';
      }

      ctrl.focusIsOnExtraBattery = false;
    }, 300);
  };

  ctrl.setFocusOnExtraBattery = function () {
    ctrl.orderService.orderData.extraBattery = '';

    ctrl.focusIsOnExtraBattery = true;
  };

  ctrl.getExtraBatteries = function () {
    if (!ctrl.cityService.selectedCity.id) {
      return;
    }

    ctrl.extraBatteryService
      .getExtraBatteries(
        ctrl.cityService.selectedCity.id,
        ctrl.orderService.orderData.leadSource
      );
  };

  ctrl.resetExtraBattery = function () {
    ctrl.extraBatteryService.selectedExtraBattery = {};

    ctrl.orderService.orderData.extraBattery = '';
  };

  ctrl.setExtraBattery = function (selectedExtraBattery) {
    ctrl.extraBatteryService.selectedExtraBattery = selectedExtraBattery;

    ctrl.orderService.orderData.extraBattery = selectedExtraBattery.amper + 'Ah';

    if (selectedExtraBattery.discount === 0) {
      ctrl.paymentService.priceMode = 'normal';
    } else {
      ctrl.paymentService.priceMode = 'discount';
    }
  };

  // METHODS - Firebase
  ctrl.getClientData = function () {
    var ref = firebase
                .database()
                .ref()
                .child('attendant')
                .orderByChild('email')
                .equalTo(localStorage.getItem('currentUserEmail'))
                .limitToFirst(1);

    var angularFireAttendant = $firebaseObject(ref);

    angularFireAttendant.$watch(function () {
      angularFireAttendant
        .$loaded()
        .then(function () {

        }, function (reason) {
          toaster.pop('error', 'Não foi possível se conectar ao Firebase para colher dados da ligação atual.');

          $log.error('ctrl.getClientData', reason);
        });
    });
  };

  // METHODS - Installments
  ctrl.getInstallmentsList = function (number) {
    var installmentsList = [];

    for (var i = 1; i <= number; i++) {
      installmentsList.push(i);
    }

    return installmentsList.reverse();
  };

  // METHODS - Order
  ctrl.copyToClipboard = function () {
    clipboard.copyText(ctrl.orderService.resume);

    ctrl.orderService.resume = '';

    toaster.pop('success', 'Pronto. Resumo copiado!');
  };

  ctrl.placeOrder = function () {
    ctrl.orderService.orderData.assistenciaFacilPoc = false;

    if (ctrl.orderService.orderData.battery.price === 0) {
      toaster.pop('error', 'Bateria indisponível.');

      return;
    } else if (ctrl.batteryService.complexInstallation) {
      toaster.pop('error', 'Veículo com troca complexa.');

      return;
    } else if (
      ctrl.cityService.selectedCity.city !== ctrl.orderService.orderData.address.city &&
      !ctrl.cityService.ignoreDifferentCity
    ) {
      toaster.pop('error', 'O endereço selecionado não é da cidade ' + ctrl.cityService.selectedCity.city + '.');

      return;
    } else if (
      ctrl.localizationService.addressIsFromAnotherCity &&
      !ctrl.cityService.ignoreDifferentCity
    ) {
      return;
    }

    if (!ctrl.isOpen && !ctrl.checkedVoucherUber && ctrl.voucherUberCity) {
      ctrl.toggleVoucherUberModal();
    } else {
      var timeBeforeTheRequet = moment();

      if (!ctrl.orderService.orderData.leadSource) {
        var leadSource = sessionStorage.getItem('mfaCallDataCallLeadSource');

        if (leadSource) {
          ctrl.orderService.orderData.leadSource = leadSource;
        } else if (!leadSource) {
          ctrl.callMonitorService.getCallData(localStorage.getItem('currentUserEmail'));

          ctrl.orderService.orderData.leadSource = ctrl.callMonitorService.callData.lead_source;
        }
      }

      ctrl.orderService
        .placeOrder()
        .then(function () {
          ctrl.resetOrder();

          ctrl.modalIsVisible = true;
          ctrl.modalResumeIsVisible = true;
        }, function (error) {
          var duration = moment.duration(moment().diff(timeBeforeTheRequet));
          var minutes = duration.asMinutes();

          $window.Raven
            .setUserContext({
              email: localStorage.getItem('currentUserEmail')
            })
            .setExtraContext(Object.assign(
              {
                timeOutErroMinutes: minutes
              },
              ctrl.orderService.orderData
            ))
            .captureException(error, {
              level: 'error',
              logger: 'app.mouraFacil.placeOrder',
              sampleRate: 1
            });

          toaster.pop('error', error.error.error);
        });
    }
  };

  ctrl.resetOrder = function () {
    sessionStorage.removeItem('mfaCallDataCallID');
    sessionStorage.removeItem('mfaCallDataCallLeadSource');
    sessionStorage.removeItem('mfaCallDataDiscard');
    sessionStorage.removeItem('mfaWantsToPreserve');

    ctrl.orderService.resetOrder();

    ctrl.localizationService.alternativeAddress = {};
    ctrl.openedDays = null;
    ctrl.openedHours = null;

    ctrl.today = '';

    ctrl.checkedVoucherUber = false;

    ctrl.resetCity();
    ctrl.resetMap(-23.5505199, -46.63330940000003);

    $scope.formMouraFacil.$setPristine();
    $scope.formMouraFacil.$setUntouched();

    ctrl.getClientData();

    $timeout(function () {
      $state.go('mouraFacil');
    });
  };

  // METHODS - Map
  function getRetailersMouraFacil(latitude, longitude) {
    ctrl.retailersService
      .getRetailersMouraFacil(latitude, longitude)
      .then(function () {
        // ctrl.updateBounds();
        ctrl.resetRetailer();
      });
  }

  ctrl.resetMap = function (latitude, longitude) {
    ctrl.localizationService.groundZero = {
      latitude: latitude,
      longitude: longitude
    };

    $document[0]
      .getElementById('map-search-cities-query')
      .value = '';

    NgMap
      .getMap({
        id: 'map-search-cities'
      })
      .then(function (map) {
        map.setZoom(9);
        map.setCenter(new google.maps.LatLng(latitude, longitude));
      });

    getRetailersMouraFacil(latitude, longitude);
  };

  ctrl.resetRetailer = function () {
    ctrl.retailersService.selectedRetailer = {};
  };

  ctrl.searchCitiesAutocomplete = function () {
    var input = $document[0].getElementById('map-search-cities-query');
    var searchBox = new google.maps.places.SearchBox(input, {componentRestrictions: 'br'});

    NgMap
      .getMap({
        id: 'map-search-cities'
      })
      .then(function (map) {
        map.addListener('bounds_changed', function () {
          searchBox.setBounds(map.getBounds());
        });

        map.setCenter(new google.maps.LatLng(ctrl.localizationService.groundZero.latitude, ctrl.localizationService.groundZero.longitude));
      });

    searchBox.addListener('places_changed', function () {
      var places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      for (var i = 0; i < places[0].address_components.length; ++i) {
        var component = places[0].address_components[i];

        if (component.types.indexOf('route') > -1) {
          ctrl.localizationService.alternativeAddress.street = component.long_name;
        } else if (component.types.indexOf('street_number') > -1) {
          ctrl.localizationService.alternativeAddress.number = component.long_name;
        } else if (component.types.indexOf('sublocality') > -1) {
          ctrl.localizationService.alternativeAddress.neighborhood = component.long_name;
        } else if (component.types.indexOf('locality') > -1) {
          ctrl.localizationService.alternativeAddress.city = component.long_name;
        } else if (component.types.indexOf('administrative_area_level_2') > -1) {
          ctrl.localizationService.alternativeAddress.city = component.long_name;
        } else if (component.types.indexOf('administrative_area_level_1') > -1) {
          ctrl.localizationService.alternativeAddress.state = component.short_name;
        } else if (component.types.indexOf('postal_code') > -1) {
          ctrl.localizationService.alternativeAddress.zipCode = component.long_name.replace(/-/g, '');
        }

        ctrl.localizationService.alternativeAddress.latitude = places[0].geometry.location.lat();
        ctrl.localizationService.alternativeAddress.longitude = places[0].geometry.location.lng();
      }

      getRetailersMouraFacil(ctrl.localizationService.alternativeAddress.latitude, ctrl.localizationService.alternativeAddress.longitude);

      NgMap
        .getMap({
          id: 'map-search-cities'
        })
        .then(function (map) {
          map.setZoom(9);
          map.setCenter(new google.maps.LatLng(ctrl.localizationService.alternativeAddress.latitude, ctrl.localizationService.alternativeAddress.longitude));

          ctrl.localizationService.groundZero = {
            latitude: ctrl.localizationService.alternativeAddress.latitude,
            longitude: ctrl.localizationService.alternativeAddress.longitude
          };
        });
    });
  };

  ctrl.selectRetailer = function (event, retailer) {
    ctrl.retailersService.selectedRetailer = retailer;
  };

  ctrl.updateBounds = function () {
    var bounds = new google.maps.LatLngBounds();

    ctrl.retailersService.retailersMouraFacil.forEach(function (retailer) {
      bounds.extend({
        lat: retailer.latitude,
        lng: retailer.longitude
      });
    });

    NgMap
      .getMap({
        id: 'map-search-cities'
      })
      .then(function (map) {
        map.fitBounds(bounds);
      });
  };

  ctrl.useAlternativeAddress = function () {
    if (ctrl.cityService.topCities.length > 0) {
      for (var i = 0; i < ctrl.cityService.topCities.length; i++) {
        if (ctrl.cityService.topCities[i].city === ctrl.retailersService.selectedRetailer.city) {
          ctrl.setCity(ctrl.cityService.topCities[i], false);

          break;
        }
      }
    }

    ctrl.orderService.orderData.address.street = ctrl.localizationService.alternativeAddress.street;
    ctrl.orderService.orderData.address.number = ctrl.localizationService.alternativeAddress.number;
    ctrl.orderService.orderData.address.neighborhood = ctrl.localizationService.alternativeAddress.neighborhood;
    ctrl.orderService.orderData.address.city = ctrl.localizationService.alternativeAddress.city;
    ctrl.orderService.orderData.address.state = ctrl.localizationService.alternativeAddress.state;
    ctrl.orderService.orderData.address.zipCode = ctrl.localizationService.alternativeAddress.zipCode;
    ctrl.orderService.orderData.address.latitude = ctrl.localizationService.alternativeAddress.latitude;
    ctrl.orderService.orderData.address.longitude = ctrl.localizationService.alternativeAddress.longitude;

    ctrl.localizationService.alternativeAddress = {};
    ctrl.retailersService.selectedRetailer = {};

    ctrl.localizationService.addressIsFromAnotherCity = false;
    ctrl.cityService.ignoreDifferentCity = true;
  };

  // METHODS - Modals
  ctrl.hidePreviousClientModal = function (discardData) {
    if (discardData) {
      ctrl.resetOrder();

      getCallData(localStorage.getItem('currentUserEmail'));

      toaster.pop('success', 'O formulário foi preenchido com os dados da ligação atual.');

      sessionStorage.setItem('mfaCallDataDiscard', 'true');
      sessionStorage.setItem('mfaWantsToPreserve', 'false');
    } else {
      sessionStorage.setItem('mfaCallDataDiscard', 'false');
      sessionStorage.setItem('mfaWantsToPreserve', 'true');
    }

    ctrl.modalIsVisible = false;
    ctrl.modalPreviousClientIsVisible = false;
  };

  ctrl.showPreviousClientModal = function () {
    ctrl.modalIsVisible = true;
    ctrl.modalPreviousClientIsVisible = true;
  };

  // METHODS - Partner
  ctrl.verifyPartner = function (partner) {
    if (partner === 'mercado_livre_premium') {
      ctrl.mercadoLivrePartner = 'mercadolivrepremium';
    }

    if (partner === 'mercadolivre_leadsource') {
      ctrl.mercadoLivrePartner = 'mercado_livre';
    }

    if (!partner || !partner.includes('mercado')) {
      ctrl.mercadoLivrePartner = '';
    }
  };

  // METHODS - Schedule
  ctrl.getOpenDays = function () {
    ctrl.openedDays = null;
    ctrl.openedHours = null;

    ctrl.openHoursService.getNextDays(ctrl.cityService.selectedCity.city, ctrl.cityService.selectedCity.state)
      .then(function (response) {
        ctrl.openedDays = response;

        if (!ctrl.orderService.orderData.selectedScheduleDate) {
          ctrl.orderService.orderData.selectedScheduleDate = ctrl.openedDays[0];
        }

        if (ctrl.openedDays[0]) {
          ctrl.openedHours = ctrl.getHours(ctrl.openedDays[0]);
        }
      });

    ctrl.isOpen = $sessionStorage.isOpen;
  };

  ctrl.getOpenHours = function () {
    ctrl.openHoursService
      .getOpenHours(ctrl.cityService.selectedCity.city, ctrl.cityService.selectedCity.state)
      .$promise
      .then(function (response) {
        var formattedOpenHours = {};

        response.schedule.forEach(function (item) {
          var day = item.day;
          var opens = item.opens.split(':');
          var ends = item.ends.split(':');

          var openHour = parseInt(opens[0], 0);
          var openMinute = parseInt(opens[1], 0);
          var endHour = parseInt(ends[0], 0);
          var endMinute = parseInt(ends[1], 0);

          var hoursArray = [[openHour, openMinute], [endHour, endMinute]];

          formattedOpenHours[day] = hoursArray;
        });

        ctrl.openHours = formattedOpenHours;
      });
  };

  ctrl.getHours = function (day) {
    var availableHours = createAvailableHoursOptions(day);

    if (day.hours.intervals.length) {
      var intervalHoursOfDay = createIntervalOptions(day.hours.intervals);
      availableHours = removeIntervalOptions(availableHours, intervalHoursOfDay);
    }

    availableHours = addTimeDifferenceRuleForTodayOptions(availableHours, day.day);

    if (ctrl.orderService.orderData.selectedScheduleTime) {
      var hourNotFound = true;

      for (var j = 0; j < availableHours.length; j++) {
        if (availableHours[j].hour === ctrl.orderService.orderData.selectedScheduleTime.hour) {
          ctrl.orderService.orderData.selectedScheduleTime = availableHours[j];
          hourNotFound = false;
        }
      }

      if (hourNotFound) {
        ctrl.orderService.orderData.selectedScheduleTime = availableHours[0];
      }
    } else if (availableHours) {
      ctrl.orderService.orderData.selectedScheduleTime = availableHours[0];
    }

    return availableHours;
  };

  ctrl.getToday = function () {
    var xmlHttp;

    function getServerTime() {
      try {
        // Chrome, Firefox, Opera, Safari
        xmlHttp = new XMLHttpRequest();
      } catch (err1) {
        // Internet Explorer
        try {
          xmlHttp = new ActiveXObject('Msxml2.XMLHTTP');
        } catch (err2) {
          // ¯\_(ツ)_/¯
          try {
            xmlHttp = new ActiveXObject('Microsoft.XMLHTTP');
          } catch (eerr3) {
            // AJAX not supported. Using CPU time
          }
        }
      }

      xmlHttp.open('HEAD', $window.location.href.toString(), false);
      xmlHttp.setRequestHeader('Content-Type', 'text/html');
      xmlHttp.send('');

      return xmlHttp.getResponseHeader('Date');
    }

    var serverDate = moment(getServerTime());

    var date = serverDate._d;
    var day = date.getDay();

    if (day === 0) {
      ctrl.today = 'domingo';
    } else if (day === 1) {
      ctrl.today = 'segunda';
    } else if (day === 2) {
      ctrl.today = 'terça';
    } else if (day === 3) {
      ctrl.today = 'quarta';
    } else if (day === 4) {
      ctrl.today = 'quinta';
    } else if (day === 5) {
      ctrl.today = 'sexta';
    } else if (day === 6) {
      ctrl.today = 'sábado';
    }
  };

  // METHODS - Toggles
  ctrl.toggleBatteryDetailsModal = function () {
    if (ctrl.modalBatteryDetailsIsVisible) {
      ctrl.modalIsVisible = false;
      ctrl.modalBatteryDetailsIsVisible = false;
    } else {
      ctrl.modalIsVisible = true;
      ctrl.modalBatteryDetailsIsVisible = true;
    }
  };

  ctrl.toggleExtraBatteryDetailsModal = function () {
    if (ctrl.modalExtraBatteryDetailsIsVisible) {
      ctrl.modalIsVisible = false;
      ctrl.modalExtraBatteryDetailsIsVisible = false;
    } else {
      ctrl.modalIsVisible = true;
      ctrl.modalExtraBatteryDetailsIsVisible = true;
    }
  };

  ctrl.toggleMapLevel1 = function () {
    if (ctrl.localizationService.mapLevel1IsVisible) {
      ctrl.localizationService.mapLevel1IsVisible = false;
    } else {
      ctrl.localizationService.mapLevel1IsVisible = true;
    }
  };

  ctrl.toggleMapLevel2 = function () {
    if (ctrl.localizationService.mapLevel2IsVisible) {
      ctrl.localizationService.mapLevel2IsVisible = false;
    } else {
      ctrl.localizationService.mapLevel2IsVisible = true;
    }
  };

  ctrl.toggleMapLevel3 = function () {
    if (ctrl.localizationService.mapLevel3IsVisible) {
      ctrl.localizationService.mapLevel3IsVisible = false;
    } else {
      ctrl.localizationService.mapLevel3IsVisible = true;
    }
  };

  ctrl.toggleMapModal = function () {
    if (ctrl.modalMapIsVisible) {
      ctrl.modalIsVisible = false;
      ctrl.modalMapIsVisible = false;
    } else {
      ctrl.modalIsVisible = true;
      ctrl.modalMapIsVisible = true;

      $timeout(function () {
        $document[0]
        .getElementById('map-search-cities-query')
        .focus();
      }, 300);
    }
  };

  ctrl.toggleResetModal = function () {
    if (ctrl.modalResetIsVisible) {
      ctrl.modalIsVisible = false;
      ctrl.modalResetIsVisible = false;
    } else {
      ctrl.modalIsVisible = true;
      ctrl.modalResetIsVisible = true;
    }
  };

  ctrl.toggleResumeModal = function () {
    if (ctrl.modalResumeIsVisible) {
      ctrl.orderService.resume = '';

      ctrl.modalIsVisible = false;
      ctrl.modalResumeIsVisible = false;
    } else {
      ctrl.modalIsVisible = true;
      ctrl.modalResumeIsVisible = true;
    }
  };

  ctrl.toggleVoucherUberModal = function () {
    if (ctrl.modalVoucherUberIsVisible) {
      ctrl.modalIsVisible = false;
      ctrl.modalVoucherUberIsVisible = false;
    } else {
      ctrl.modalIsVisible = true;
      ctrl.modalVoucherUberIsVisible = true;
    }
  };

  // METHODS - Vehicles
  ctrl.blurVehicle = function () {
    $timeout(function () {
      if (ctrl.vehicleService.selectedVehicle.car) {
        ctrl.orderService.orderData.vehicle = ctrl.vehicleService.selectedVehicle.car;
      }

      if (ctrl.cityService.selectedCity.id) {
        ctrl.getTopVehicles(ctrl.cityService.selectedCity.id);
      }

      ctrl.focusIsOnVehicle = false;
    }, 300);
  };

  ctrl.setFocusOnVehicle = function () {
    ctrl.orderService.orderData.vehicle = '';

    ctrl.focusIsOnVehicle = true;
  };

  ctrl.getTopVehicles = function (cityId) {
    ctrl.vehicleService.getTopVehicles(cityId);
  };

  ctrl.getMoreTopVehicles = function (cityId) {
    ctrl.vehicleService.getMoreTopVehicles(cityId);
  };

  ctrl.resetVehicle = function () {
    ctrl.orderService.orderData.car = {};
    ctrl.vehicleService.selectedVehicle = {};

    ctrl.orderService.orderData.vehicle = null;
    ctrl.orderService.orderData.vehicleType = null;

    ctrl.batteryService.complexInstallation = false;

    resetBattery();

    ctrl.resetExtraBattery();
  };

  ctrl.setVehicle = function (selectedVehicle) {
    ctrl.resetExtraBattery();

    ctrl.orderService.orderData.car = selectedVehicle;
    ctrl.orderService.orderData.vehicle = selectedVehicle.car;
    ctrl.vehicleService.selectedVehicle = selectedVehicle;

    if (selectedVehicle.complex) {
      toaster.pop('error', 'Atenção! O veículo escolhido possui troca complexa.');

      ctrl.batteryService.complexInstallation = true;
    } else {
      ctrl.batteryService.complexInstallation = false;
    }

    ctrl.getBattery();
  };

  // METHODS - Vouchers
  ctrl.setVoucherUber = function (option) {
    ctrl.orderService.orderData.voucherUber = option;

    ctrl.checkedVoucherUber = true;
  };

  // CONNECTIONS
  ctrl.callMonitorService.startEventConnection(localStorage.getItem('currentUserEmail'));

  // WATCHERS
  $scope.$watch('$ctrl.orderService.orderData.address.city', function (newValue) {
    if (ctrl.focusIsOnCity) {
      ctrl.cityService.searchCities(newValue);
    }
  });

  $scope.$watch('$ctrl.orderService.orderData.leadSource', function (newValue) {
    ctrl.cityService.getTopCities(true, newValue);
  });

  $scope.$watch('$ctrl.orderService.orderData.origin', function (newValue, oldValue) {
    if (newValue === 'phone') {
      ctrl.callMonitorService.getCallData(localStorage.getItem('currentUserEmail'));
    }

    if (newValue !== oldValue) {
      resetBattery();

      ctrl.resetExtraBattery();
      ctrl.resetVehicle();
    }

    if (newValue === 'mercadolivre' || newValue === 'origin_mercadolivrepremium') {
      ctrl.orderService.orderData.additionalInfo = 'Mercado Livre: Não cobrar ao cliente. Enviar NF em XML para o e-mail: notafiscal@mourafacil.com.';

      if (newValue === 'mercadolivre') {
        ctrl.orderService.orderData.leadSource = 'mercadolivre_leadsource';
      } else if (newValue === 'origin_mercadolivrepremium') {
        ctrl.orderService.orderData.leadSource = 'mercado_livre_premium';
      }
    } else {
      ctrl.orderService.orderData.additionalInfo = '';
      ctrl.orderService.orderData.leadSource = '';
    }
  });

  $scope.$watch('$ctrl.orderService.orderData.vehicle', function (newValue) {
    if (ctrl.focusIsOnVehicle) {
      ctrl.vehicleService.searchVehicles(newValue);
    }
  });

  $scope.$watch(function () {
    return $sessionStorage.isOpen;
  }, function (newValue) {
    ctrl.isOpen = newValue;

    if (ctrl.isOpen) {
      ctrl.orderService.orderData.scheduled = false;
    } else {
      ctrl.orderService.orderData.scheduled = true;
    }
  });

  $scope.$watch('$ctrl.callMonitorService.callData', function (newValue) {
    ctrl.callData = newValue;

    getCallData();
  });

  $scope.$watch(
    function () {
      return localStorage.getItem('currentUserEmail');
    },
    function (newValue) {
      if (!newValue) {
        ctrl.firebaseAuthService.signOut();

        toaster.pop('info', 'Sua sessão expirou. Por favor, entre novamente.');

        return ctrl.callMonitorService.closeEventConnection();
      }
    }
  );

  // CALLS
  ctrl.callMonitorService.getCallData(localStorage.getItem('currentUserEmail'));

  getRetailersMouraFacil(
    ctrl.localizationService.groundZero.latitude,
    ctrl.localizationService.groundZero.longitude
  );

  ctrl.addressAutocomplete();
  ctrl.getClientData();
  ctrl.getToday();
  ctrl.searchCitiesAutocomplete();
}
