angular
  .module('app')
  .config(resetPasswordRoutes);

/** @ngInject */
function resetPasswordRoutes($stateProvider) {
  $stateProvider.state('resetPassword', {
    url: '/redefinir-senha',
    component: 'resetPassword',
    resolve: {
      currentAuth: ['firebaseAuthFactory', function (firebaseAuthFactory) {
        return firebaseAuthFactory.$waitForSignIn();
      }]
    }
  });
}
