angular
  .module('app')
  .factory('techEvaluationFactory', techEvaluationFactory);

/** @ngInject */
function techEvaluationFactory($resource, MOURA_FACIL_DIGITAL_SERVICES_API) {
  return $resource(MOURA_FACIL_DIGITAL_SERVICES_API + '/orders/:shortId/af',
    {
      shortId: '@shortId'
    },
    {},
    {
      stripTrailingSlashes: false
    }
  );
}
