angular
  .module('app')
  .factory('$exceptionHandler', exceptionHandler);

/** @ngInject */
function exceptionHandler($log, $window) {
  'ngInject';
  return function sentryExceptionHandler(exception, cause) {
    $log.warn(exception, cause);

    $window.Raven.captureException(exception);
  };
}
