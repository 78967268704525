angular
  .module('app')
  .config(signInRoutes);

/** @ngInject */
function signInRoutes($stateProvider) {
  $stateProvider.state('signIn', {
    url: '/',
    component: 'signIn',
    resolve: {
      currentAuth: ['firebaseAuthFactory', function (firebaseAuthFactory) {
        return firebaseAuthFactory.$waitForSignIn();
      }]
    }
  });
}
