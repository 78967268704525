angular
  .module('app')
  .config(routes);

/** @ngInject */
function routes($locationProvider, $urlRouterProvider) {
  $locationProvider
    .hashPrefix('!')
    .html5Mode(true);

  $urlRouterProvider.otherwise('/');
}
