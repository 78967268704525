angular
  .module('app')
  // COMMONS
  .constant('TDS_GEOLOC', 'https://geoloc.sodet.com.br/api/v1')

  // ----------------------------------------------------------------------- //
  // STAGING
  // Consome e envia para APIs de development
  // ----------------------------------------------------------------------- //
  //
  // .constant('FIREBASE_API_KEY', 'AIzaSyBSEzhf477imtGAFl-7JRX6qDu0Xi53AHA')
  // .constant('FIREBASE_AUTH_DOMAIN', 'mf-digital-services-dev.firebaseapp.com')
  // .constant('FIREBASE_DATABASE_URL', 'https://mf-digital-services-dev.firebaseio.com')
  // .constant('FIREBASE_MESSAGING_SENDER_ID', '642846806578')
  // .constant('FIREBASE_PROJECT_ID', 'mf-digital-services-dev')
  // .constant('FIREBASE_STORAGE_BUCKET', 'mf-digital-services-dev.appspot.com')

  // .constant('MOURA_FACIL_API', 'https://mf-api-staging.herokuapp.com/api/v1')
  // .constant('MOURA_FACIL_CALL_MONITOR_API', 'https://mf-call-monitor-dev.herokuapp.com/api/v1')
  // .constant('MOURA_FACIL_REALTIME_API', 'https://mf-realtime-staging.herokuapp.com/v1')
  // .constant('MOURA_SEARCH_API_KEY', '9ppcKLY5KW806Cu1epJHT6yIJm3EEwni7OYXVTwx')
  // .constant('MOURA_SEARCH_API', 'https://719n5gx6s8.execute-api.us-east-1.amazonaws.com/dev/search/api/v1')
  // .constant('MOURA_SEARCH_API_V2', 'https://719n5gx6s8.execute-api.us-east-1.amazonaws.com/dev/search/api/v2')
  // .constant('MOURA_SERVICES_API', 'http://moura-services-dev.herokuapp.com/api/v1')

  // .constant('MOURA_FACIL_DIGITAL_SERVICES_API', 'https://mf-digitalservices-dev.herokuapp.com');

  // ----------------------------------------------------------------------- //
  // STAGING
  // Consome das APIs de production e envia para APIs de development
  // ----------------------------------------------------------------------- //
  //
  // .constant('FIREBASE_API_KEY', 'AIzaSyBSEzhf477imtGAFl-7JRX6qDu0Xi53AHA')
  // .constant('FIREBASE_AUTH_DOMAIN', 'mf-digital-services-dev.firebaseapp.com')
  // .constant('FIREBASE_DATABASE_URL', 'https://mf-digital-services-dev.firebaseio.com')
  // .constant('FIREBASE_MESSAGING_SENDER_ID', '642846806578')
  // .constant('FIREBASE_PROJECT_ID', 'mf-digital-services-dev')
  // .constant('FIREBASE_STORAGE_BUCKET', 'mf-digital-services-dev.appspot.com')

  // .constant('MOURA_FACIL_API', 'https://mf-api-prod.herokuapp.com/api/v1')
  // .constant('MOURA_FACIL_CALL_MONITOR_API', 'https://callmon.mourafacil.com/api/v1')
  // .constant('MOURA_FACIL_REALTIME_API', 'https://mf-realtime-staging.herokuapp.com/v1')
  // .constant('MOURA_SEARCH_API_KEY', '9ppcKLY5KW806Cu1epJHT6yIJm3EEwni7OYXVTwx')
  // .constant('MOURA_SEARCH_API', 'https://719n5gx6s8.execute-api.us-east-1.amazonaws.com/prod/search/api/v1')
  // .constant('MOURA_SEARCH_API_V2', 'https://719n5gx6s8.execute-api.us-east-1.amazonaws.com/prod/search/api/v2')
  // .constant('MOURA_SERVICES_API', 'https://moura-services.herokuapp.com/api/v1')

  // .constant('MOURA_FACIL_DIGITAL_SERVICES_API', 'https://mf-digitalservices-dev.herokuapp.com');

  // ----------------------------------------------------------------------- //
  // DEPLOY
  // Consome e envia para APIs de production
  // ----------------------------------------------------------------------- //
  //
  .constant('FIREBASE_API_KEY', 'AIzaSyBxm9qLzQ8_yVx9wt5-kIKPDZTfp6TKseA')
  .constant('FIREBASE_AUTH_DOMAIN', 'mf-digital-services.firebaseapp.com')
  .constant('FIREBASE_DATABASE_URL', 'https://mf-digital-services.firebaseio.com')
  .constant('FIREBASE_MESSAGING_SENDER_ID', '1018422659430')
  .constant('FIREBASE_PROJECT_ID', 'mf-digital-services')
  .constant('FIREBASE_STORAGE_BUCKET', 'mf-digital-services.appspot.com')

  .constant('MOURA_FACIL_API', 'https://mf-api-prod.herokuapp.com/api/v1')
  .constant('MOURA_FACIL_CALL_MONITOR_API', 'https://callmon.mourafacil.com/api/v1')
  .constant('MOURA_FACIL_REALTIME_API', 'https://mf-realtime-staging.herokuapp.com/v1')
  .constant('MOURA_SEARCH_API_KEY', '9ppcKLY5KW806Cu1epJHT6yIJm3EEwni7OYXVTwx')
  .constant('MOURA_SEARCH_API', 'https://719n5gx6s8.execute-api.us-east-1.amazonaws.com/prod/search/api/v1')
  .constant('MOURA_SEARCH_API_V2', 'https://719n5gx6s8.execute-api.us-east-1.amazonaws.com/prod/search/api/v2')
  .constant('MOURA_SERVICES_API', 'https://moura-services.herokuapp.com/api/v1')

  .constant('MOURA_FACIL_DIGITAL_SERVICES_API', 'https://api.staging.mourafacil.com.br');
