angular
  .module('app')
  .factory('getOpenHoursFactory', getOpenHoursFactory);

/** @ngInject */
function getOpenHoursFactory($resource, MOURA_SEARCH_API_V2, MOURA_SEARCH_API_KEY) {
  return $resource(MOURA_SEARCH_API_V2 + '/horarios', {
    estado: '@estado',
    cidade: '@cidade'
  }, {
    get: {
      headers: {
        'x-api-key': MOURA_SEARCH_API_KEY
      },
      method: 'GET'
    }
  });
}
