angular
  .module('app')
  .config(assistenciaFacilRoutes);

/** @ngInject */
function assistenciaFacilRoutes($stateProvider) {
  $stateProvider.state('assistenciaFacil', {
    url: '/assistencia-facil',
    component: 'assistenciaFacil',
    resolve: {
      currentAuth: ['firebaseAuthFactory', function (firebaseAuthFactory) {
        return firebaseAuthFactory.$requireSignIn();
      }]
    }
  });
}
