angular
  .module('app')
  .factory('getCardsFlagsFactory', getCardsFlagsFactory);

/** @ngInject */
function getCardsFlagsFactory($resource, MOURA_SEARCH_API, MOURA_SEARCH_API_KEY) {
  return $resource(MOURA_SEARCH_API + '/creditcards', {}, {
    query: {
      headers: {
        'x-api-key': MOURA_SEARCH_API_KEY
      },
      isArray: true,
      method: 'GET'
    }
  });
}
