angular
  .module('app')
  .factory('getTopCitiesFactory', getTopCitiesFactory);

/** @ngInject */
function getTopCitiesFactory($resource, MOURA_SEARCH_API, MOURA_SEARCH_API_KEY) {
  return $resource(MOURA_SEARCH_API + '/topcidade', {
    limit: '@limit',
    offset: '@offset',
    partner: '@partner'
  }, {
    query: {
      headers: {
        'x-api-key': MOURA_SEARCH_API_KEY
      },
      isArray: true,
      method: 'GET'
    }
  });
}
