angular
  .module('app')
  .factory('getTopVehiclesFactory', getTopVehiclesFactory);

/** @ngInject */
function getTopVehiclesFactory($resource, MOURA_SEARCH_API, MOURA_SEARCH_API_KEY) {
  return $resource(MOURA_SEARCH_API + '/topcarro', {
    cidade: '@cidade',
    limit: '@limit',
    offset: '@offset',
    vehicleType: '@vehicleType'
  }, {
    query: {
      headers: {
        'x-api-key': MOURA_SEARCH_API_KEY
      },
      isArray: true,
      method: 'GET'
    }
  });
}
