angular
  .module('app')
  .service('callMonitorService', callMonitorService);

/** @ngInject */
function callMonitorService($rootScope, $log, $q, callMonitorFactoryEmail, MOURA_FACIL_CALL_MONITOR_API) {
  var self = {
    // OBJECTS
    callData: {},
    source: null,

    // FLAGS
    isGettingCallData: null,

    // METHODS
    getCallData: function (email) {
      if (self.isGettingCallData) {
        return;
      }

      self.isGettingCallData = true;

      var defer = $q.defer();

      callMonitorFactoryEmail
        .get({
          email: email
        })
        .$promise
        .then(function (response) {
          self.callData = response;

          self.isGettingCallData = false;

          defer.resolve(response);
        }, function (reason) {
          self.callData = {};

          $log.error('callMonitorService');

          self.isGettingCallData = false;

          defer.resolve(reason);
        });

      return defer.promise;
    },

    startEventConnection: function (email) {
      if (self.source) {
        self.source.close();
      }

      self.source = new EventSource(MOURA_FACIL_CALL_MONITOR_API + '/events/stream-sse?email=' + email);

      self.source.onmessage = function (event) {
        $rootScope.$apply(function () {
          self.callData = JSON.parse(event.data);
        });
      };

      self.source.onerror = function () {
        $rootScope.$apply(function () {
          $log.error('callMonitorService');
        });
      };
    },

    closeEventConnection: function () {
      if (self.source) {
        self.source.close();
      }
    }
  };

  return self;
}
