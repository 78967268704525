angular
  .module('app')
  .config(dashboardRoutes);

/** @ngInject */
function dashboardRoutes($stateProvider) {
  $stateProvider.state('dashboard', {
    url: '/dashboard',
    component: 'dashboard',
    resolve: {
      currentAuth: ['firebaseAuthFactory', function (firebaseAuthFactory) {
        return firebaseAuthFactory.$requireSignIn();
      }]
    }
  });
}
