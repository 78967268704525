angular
  .module('app')
  .factory('getExtraBatteriesFactory', getExtraBatteriesFactory);

/** @ngInject */
function getExtraBatteriesFactory($resource, MOURA_SEARCH_API, MOURA_SEARCH_API_KEY) {
  return $resource(MOURA_SEARCH_API + '/cidade/:cidade/bateria', {}, {
    query: {
      headers: {
        'x-api-key': MOURA_SEARCH_API_KEY
      },
      isArray: true,
      method: 'GET'
    }
  });
}
