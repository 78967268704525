angular
  .module('app')
  .component('navbar', {
    templateUrl: 'app/components/navbar/navbar.html',
    controller: NavbarController
  });

/** @ngInject */
function NavbarController(firebaseAuthService) {
  // THIS
  var ctrl = this;

  // SERVICES
  ctrl.firebaseAuthService = firebaseAuthService;

  // FLAGS
  ctrl.modalIsVisible = null;
  ctrl.modalSignOutIsVisible = null;
  ctrl.navbarIsCollapsed = true;

  // METHODS
  ctrl.toggleNavbar = function () {
    if (ctrl.navbarIsCollapsed) {
      ctrl.navbarIsCollapsed = false;
    } else {
      ctrl.navbarIsCollapsed = true;
    }
  };

  ctrl.toggleSignOutModal = function () {
    if (ctrl.modalSignOutIsVisible) {
      ctrl.modalIsVisible = false;
      ctrl.modalSignOutIsVisible = false;
    } else {
      ctrl.modalIsVisible = true;
      ctrl.modalSignOutIsVisible = true;
    }
  };
}
