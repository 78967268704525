angular
  .module('app')
  .directive('overwriteEmail', function () {
    return function () {
      var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@example\.com$/i;
      // var EMAIL_REGEXP = /^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$/i;

      return {
        require: '?ngModel',
        link: function (scope, elm, attrs, ctrl) {
          if (ctrl && ctrl.$validators.email) {
            ctrl.$validators.email = function (modelValue) {
              return ctrl.$isEmpty(modelValue) || EMAIL_REGEXP.test(modelValue);
            };
          }
        }
      };
    };
  });
