/* eslint-disable camelcase */
angular
.module('app')
.service('openHoursService', openHoursService);

/** @ngInject */
function openHoursService($sessionStorage, getOpenHoursFactory) {
  var weekdaysEN = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
  var weekdaysPT = ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'];

  var self = this;

  this.v2HourToInt = function (hour) {
    if (hour) {
      return parseInt(hour.split(':')[0]);
    }
  };

  this.getOpenHours = function (city, state) {
    return getOpenHoursFactory.get({
      cidade: city,
      estado: state
    });
  };

  this.getZone = function (city, state) {
    return this.getOpenHours(city, state).$promise
    .then(function (response) {
      return response.zone;
    });
  };

  this.getNextDays = function (city, state) {
    return this.getOpenHours(city, state).$promise
      .then(function (response) {
        var days = [];

        var now = moment();
        if (response.zone && response.dst) {
          now = moment().tz('America/Recife');
        } else if (response.zone && !response.dst) {
          now = moment().tz('America/Manaus');
        } else if (!response.zone && response.dst) {
          now = moment().tz('Atlantic/South_Georgia');
        } else if (!response.zone && !response.dst) {
          now = moment().tz('America/Recife');
        }

        for (var i = 0; days.length < 3; i++) {
          var dayForward = now.clone().add(i, 'days');
          var weekday = weekdaysEN[dayForward.day()];
          var weekdayPT = weekdaysPT[dayForward.day()];

          var shedulesFromIterationDay = response.schedule.find(function (daySchedule) {
            return daySchedule.day === weekday;
          });

          if (shedulesFromIterationDay !== undefined) {
            if (i === 0) {
              var lastHour = now.clone().add(50, 'minutes').hour();

              if (now.hour() === 23) {
                lastHour = now.hour();
              }

              if (
                lastHour <
                self.v2HourToInt(shedulesFromIterationDay.ends) + 1
              ) {
                days.push({
                  day: 'hoje',
                  weekday: weekdayPT,
                  hours: shedulesFromIterationDay,
                  fulldate: dayForward
                });
              }
            } else if (i === 1) {
              days.push({
                day: 'amanhã',
                weekday: weekdayPT,
                hours: shedulesFromIterationDay,
                fulldate: dayForward
              });
            } else {
              days.push({
                day: weekdayPT,
                weekday: weekdayPT,
                hours: shedulesFromIterationDay,
                fulldate: dayForward
              });
            }
          }
        }

        function removeDuplicateDays(days) {
          var uniqueDays = [];
          var seenDays = [];

          for (var i = 0; i < days.length; i++) {
            var day = days[i];
            if (seenDays.indexOf(day.day) === -1) {
              uniqueDays.push(day);
              seenDays.push(day.day);
            }
          }

          return uniqueDays;
        }

        days = removeDuplicateDays(days);

        var currentHour = now.hour();
        var currentDay = weekdaysEN[now.clone().day()];
        var todayHours = days[0].hours;
        var todayIntervals = days[0].hours.intervals || [];

        var isOpen = false;

        if (todayHours) {
          var isOpenNow = (
            todayHours.day === currentDay &&
            self.v2HourToInt(todayHours.opens) <= currentHour &&
            self.v2HourToInt(todayHours.ends) >= currentHour
            );
          if (todayIntervals && todayIntervals.length > 0) {
            todayIntervals.forEach(function (interval) {
              var start = self.v2HourToInt(interval.start);
              var end = self.v2HourToInt(interval.end);

              if (currentHour >= start && currentHour < end) {
                isOpenNow = false;
              }
            });
          }

          isOpen = isOpenNow;
        }

        $sessionStorage.isOpen = isOpen;

        return days;
      });
  };
}
