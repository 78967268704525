angular
  .module('app')
  .service('extraBatteryService', extraBatteryService);

/** @ngInject */
function extraBatteryService($log, getExtraBatteriesFactory) {
  var self = {
    // ARRAYS
    extraBatteries: [],
    extraBatteriesFiltered: [],

    // OBJECTS
    selectedExtraBattery: {},

    // FLAGS
    isGettingExtraBatteries: null,

    // METHODS
    getExtraBatteries: function (cityId) {
      if (self.isGettingExtraBatteries) {
        return;
      }

      self.isGettingExtraBatteries = true;

      getExtraBatteriesFactory
        .query({
          cidade: cityId
        })
        .$promise
        .then(function (response) {
          response = response.map(function (battery) {
            if (battery.discount === 0) {
              battery.discountOffer = 0;

              battery.priceOffer = battery.price - 30.00;
            } else {
              battery.priceOffer = 0;

              battery.discountOffer = battery.discount - 30.00;
            }

            return battery;
          });

          self.extraBatteries = response;

          self.isGettingExtraBatteries = false;
        }, function (reason) {
          $log.error('extraBatteryService.getExtraBatteries', reason);

          self.isGettingExtraBatteries = false;
        });
    }
  };

  return self;
}
